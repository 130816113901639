import groq from 'groq';
import {LINK_EXTERNAL} from './linkExternal';
import {LINK_INTERNAL} from './linkInternal';

export const LINK = groq`
    (_type == 'linkExternal') => {
      ${LINK_EXTERNAL}
    },
    (_type == 'linkInternal') => {
      ${LINK_INTERNAL}
    },
`;

export const LINKS = groq`
  title,
  links[] {
    _key,
    ${LINK}
  }
`;
