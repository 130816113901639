import {
  defer,
  type LinksFunction,
  type MetaFunction,
  type LoaderArgs,
  type AppLoadContext,
} from '@shopify/remix-oxygen';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useCatch,
  useLoaderData,
  useMatches,
} from '@remix-run/react';
import {ShopifySalesChannel, Seo} from '@shopify/hydrogen';
import {Layout, GenericError, NotFound, AnalyticsScripts} from '~/components';
import {seoPayload} from '~/lib/seo.server';
import styles from './styles/app.css';
import dateStyles from 'react-datepicker/dist/react-datepicker.css';

import favicon from '../public/favicon.svg';

import {DEFAULT_LOCALE} from './lib/utils';
import invariant from 'tiny-invariant';
import {Cart} from '@shopify/hydrogen/storefront-api-types';
import {useAnalytics} from './hooks/useAnalytics';
import {sanityClient} from '~/lib/sanity';
import {LINK, LINKS} from '~/queries/sanity/elements/links';
import {IMAGE} from '~/queries/sanity/elements/image';
import {SEO} from './queries/sanity/seo';
import groq from 'groq';
import {CookieData, ModalData, LayoutData, SanitySettings} from './types';
import {config} from '@fortawesome/fontawesome-svg-core';
import {
  newsletterModalClosedCookie,
  newsletterSubscribedCookie,
} from './cookie.server';
config.autoAddCss = false;

export const links: LinksFunction = () => {
  return [
    {rel: 'stylesheet', href: styles},
    {rel: 'stylesheet', href: dateStyles},
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400&display=swap',
    },
    {
      rel: 'preconnect',
      href: 'https://fonts.googleapis.com',
    },
    {
      rel: 'preconnect',
      href: 'https://fonts.gstatic.com',
    },
    {
      rel: 'preconnect',
      href: 'https://cdn.shopify.com',
    },
    {
      rel: 'preconnect',
      href: 'https://shop.app',
    },
    {rel: 'icon', type: 'image/svg+xml', href: favicon},
  ];
};

export const meta: MetaFunction = () => ({
  charset: 'utf-8',
  viewport: 'width=device-width,initial-scale=1',
});

export async function loader({request, context}: LoaderArgs) {
  const cookieHeader = request.headers.get('Cookie');
  const [
    customerAccessToken,
    cartId,
    layout,
    newsletterSubscribed,
    newsletterModalClosed,
  ] = await Promise.all([
    context.session.get('customerAccessToken'),
    context.session.get('cartId'),
    getLayoutData(context),
    newsletterSubscribedCookie.parse(cookieHeader),
    newsletterModalClosedCookie.parse(cookieHeader),
  ]);

  const url = new URL(request.url);
  const modal = url.searchParams.get('modal');

  const seo = seoPayload.root({layout, url: request.url});

  return defer({
    isLoggedIn: Boolean(customerAccessToken),
    layout,
    selectedLocale: context.storefront.i18n,
    cart: cartId ? getCart(context, cartId) : undefined,
    analytics: {
      shopifySalesChannel: ShopifySalesChannel.hydrogen,
      shopId: layout.shop.id,
    },
    modal,
    seo,
    cookies: {
      isNewsletterSubscribed: Boolean(newsletterSubscribed),
      isNewsletterModalClosed: Boolean(newsletterModalClosed),
    },
    tracking: {
      gtagId: context.env.PUBLIC_GOOGLE_GA4,
      pixelId: context.env.PUBLIC_META_PIXEL_ID,
      klaviyoCompanyId: context.env.PUBLIC_KLAVIYO_COMPANY_ID,
    },
  });
}

export default function App() {
  const data = useLoaderData<typeof loader>();
  const locale = data.selectedLocale ?? DEFAULT_LOCALE;
  const hasUserConsent = true; //TODO: get user consent from cookie modal

  useAnalytics(hasUserConsent, locale, data?.tracking);

  return (
    <html lang={locale.language} className="scroll-smooth">
      <head>
        <Seo />
        <AnalyticsScripts env={{...data?.tracking}} />
        <Meta />
        <Links />
      </head>
      <body>
        <Layout
          layout={data.layout as LayoutData}
          cookies={data.cookies as CookieData}
          modal={data.modal as ModalData}
          key={`${locale.language}-${locale.country}`}
        >
          <Outlet />
        </Layout>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export function CatchBoundary() {
  const [root] = useMatches();
  const caught = useCatch();
  const isNotFound = caught.status === 404;
  const locale = root.data?.selectedLocale ?? DEFAULT_LOCALE;

  //TODO: add useAnalytics hook here and add support for 404 errors

  return (
    <html lang={locale.language}>
      <head>
        <title>{isNotFound ? 'Not found' : 'Error'}</title>
        <Meta />
        <Links />
      </head>
      <body>
        <AnalyticsScripts env={{...root?.data?.tracking}} />
        <Layout
          cookies={root?.data?.cookies}
          layout={root?.data?.layout}
          modal={root?.data?.modal}
          key={`${locale.language}-${locale.country}`}
        >
          {isNotFound ? (
            <NotFound type={caught.data?.pageType} />
          ) : (
            <GenericError
              error={{message: `${caught.status} ${caught.data}`}}
            />
          )}
        </Layout>
        <Scripts />
      </body>
    </html>
  );
}

export function ErrorBoundary({error}: {error: Error}) {
  const [root] = useMatches();
  const locale = root?.data?.selectedLocale ?? DEFAULT_LOCALE;

  //TODO: add useAnalytics hook here and add support for tracking other errors

  return (
    <html lang={locale.language}>
      <head>
        <title>Error</title>
        <Meta />
        <Links />
      </head>
      <body>
        <AnalyticsScripts env={{...root?.data?.tracking}} />
        <Layout
          cookies={root?.data?.cookies}
          modal={root?.data?.modal}
          layout={root?.data?.layout}
        >
          <GenericError error={error} />
        </Layout>
        <Scripts />
      </body>
    </html>
  );
}

const SETTINGS_QUERY = groq`
  *[_type == 'settings'][0] {
    "menus": menu {
      main {
        ${LINKS}
      },
      quick {
        ${LINKS}
      },
      footerGeneral {
        ${LINKS}
      },
      footerSupport {
        ${LINKS}
      },
    },
    social[] {
      _key,
      platform,
      url
    },
    siteBanner {
      ...,
      body,
      active,
      link[0] {
        ${LINK}
      }
    },
    cookieConsent {
      body,
      active
    },
    contact,
    newsletterModal {
      title,
      subtitle,
      image {
        ${IMAGE}
      },
      active
    },
    ${SEO}
  }
`;

const LAYOUT_QUERY = `#graphql
  query layoutMenus(
    $language: LanguageCode
  ) @inContext(language: $language) {
    shop {
      id
      name
      description
      primaryDomain {
        url
      }
      brand {
       logo {
         image {
          url
         }
       }
     }
    }
  }
`;

async function getLayoutData({storefront}: AppLoadContext) {
  const settings = await sanityClient.fetch<SanitySettings>(SETTINGS_QUERY);

  const data = await storefront.query<LayoutData>(LAYOUT_QUERY, {
    variables: {
      language: storefront.i18n.language,
    },
  });

  // invariant(settings, 'No data returned from Sanity API');

  return {
    shop: data.shop,
    ...settings,
  };
}

const CART_QUERY = `#graphql
  query CartQuery($cartId: ID!, $country: CountryCode, $language: LanguageCode)
    @inContext(country: $country, language: $language) {
    cart(id: $cartId) {
      ...CartFragment
    }
  }

  fragment CartFragment on Cart {
    id
    checkoutUrl
    totalQuantity
    buyerIdentity {
      countryCode
      customer {
        id
        email
        firstName
        lastName
        displayName
      }
      email
      phone
    }
    lines(first: 100) {
      edges {
        node {
          id
          quantity
          attributes {
            key
            value
          }
          cost {
            totalAmount {
              amount
              currencyCode
            }
            amountPerQuantity {
              amount
              currencyCode
            }
            compareAtAmountPerQuantity {
              amount
              currencyCode
            }
          }
          merchandise {
            ... on ProductVariant {
              id
              availableForSale
              compareAtPrice {
                ...MoneyFragment
              }
              price {
                ...MoneyFragment
              }
              requiresShipping
              title
              image {
                ...ImageFragment
              }
              product {
                handle
                title
                id
              }
              selectedOptions {
                name
                value
              }
            }
          }
        }
      }
    }
    cost {
      subtotalAmount {
        ...MoneyFragment
      }
      totalAmount {
        ...MoneyFragment
      }
      totalDutyAmount {
        ...MoneyFragment
      }
      totalTaxAmount {
        ...MoneyFragment
      }
    }
    note
    attributes {
      key
      value
    }
    discountCodes {
      code
    }
  }

  fragment MoneyFragment on MoneyV2 {
    currencyCode
    amount
  }

  fragment ImageFragment on Image {
    id
    url
    altText
    width
    height
  }
`;

export async function getCart({storefront}: AppLoadContext, cartId: string) {
  invariant(storefront, 'missing storefront client in cart query');

  const {cart} = await storefront.query<{cart?: Cart}>(CART_QUERY, {
    variables: {
      cartId,
      country: storefront.i18n.country,
      language: storefront.i18n.language,
    },
    cache: storefront.CacheNone(),
  });

  return cart;
}
